<template>
	<div data-route>
		<page-header
			heading="Keywords"
			:action="getHeaderAction"
		/>
		<div data-element="main">
			<table-view
				:table="getTable"
				:showSearch="false"
			/>
		</div>
	</div>
</template>

<script>

	import PageHeader  from '@/components/ui/PageHeader';
	import TableView   from '@/components/ui/views/tableView/TableView';
	import api         from '@/services/api';

	export default {
		metaInfo: {
			title: 'Keywords'
		},
		components: {
			PageHeader,
			TableView
		},
		data: () => ({
			keywords: [],
			newKeyword: ''
		}),
		computed: {
			getTable () {
				return {
					headings: [
						'Keyword',
						'Focuses'
					],
					rows: this.keywords.map((keyword) => {
						return {
							route: `/admin/keywords/${keyword.id}/`,
							columns: [
								keyword.title,
								keyword.focus.map((keyword) => {
									return keyword.title;
								}).join(', ')
							]
						};
					})
				};
			},
			getHeaderAction () {
				return {
					text: 'Add keyword',
					route: '/admin/keywords/create'
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/keywords',
						text: 'Keywords'
					}
				].filter(Boolean);
			}
		},
		created () {
			this.setKeywords();
		},
		methods: {
			async setKeywords () {
				const keywords = await api.admin.getAllKeywords();
				if (!keywords) {
					this.$store.commit('ui/showError');
					return;
				}
				this.keywords = keywords;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};
</script>
